import {abs, subset, index, sign, min, cos} from 'mathjs';

export default function GHaft(x, Us, k2, G0, h, eps) {
    let G_q;
    let r_haft;

    if(h === 1) {
        G_q = G0 * cos(subset(x, index(0, 0)));
    } else {
        G_q = G0;
    }

    if(abs(subset(x, index(1, 0))) > eps) {
        r_haft = k2 * sign(subset(x, index(1, 0)));
    } else {
        r_haft = min(Us, k2) * sign(Us)
    }

    return {
        G_q,
        r_haft
    }
}
