import {SIMULATION_SET_VALUE} from "../../config/actions";

let defaultState = {
    M_Stern: null,
    k1: null,
    k2: null,
    G0: null,
    dt_darst: null,
    lauf: null,
    qs: null,
    qp: null,
    qps: null,
    qs_q: null,
    qsp_qp: null,
    Zeit: null,
    status: null,
    Streckensprung: 0,
    Geschwindigkeitsregelung: 0
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case SIMULATION_SET_VALUE: {
            return {
                ...state,
                [action.name]: action.value
            }
        }
        default:
            return state;
    }
}
