import {matrix, subset, index, add, multiply} from 'mathjs';
import GHaft from "./GHaft";

export default function RK2(Us, x, dt, M_Stern, k1, k2, G0, h, eps) {
    let {G_q, r_haft} = GHaft(x, Us, k2, G0, h, eps);

    let f_xk = matrix([
        [subset(x, index(1, 0))],
        [(1 / M_Stern) * (Us - G_q - k1 * subset(x, index(1, 0)) - r_haft)]
    ]);
    let xzw = add(x, multiply((dt / 2), f_xk));
    ({G_q, r_haft} = GHaft(xzw, Us, k2, G0, h, eps));
    let f_xzw = matrix([
        [subset(xzw, index(1, 0))],
        [(1 / M_Stern) * (Us - G_q - k1 * subset(xzw, index(1, 0)) - r_haft)]
    ]);
    return add(x, multiply(dt, f_xzw));
}
