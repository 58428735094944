export default function redus_alg(par_int, alpha, beta, e_akt, qp_akt, qps_akt, e_sum_alt) {
    let e_sum_neu = e_sum_alt + e_akt;
    let Stellwert = beta * qps_akt - alpha * qp_akt + par_int * e_sum_alt;
    e_sum_alt = e_sum_neu;

    return {
        Stellwert,
        e_sum_alt
    }
}
