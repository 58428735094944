import {combineReducers} from "redux";
import routeParametersReducer from "./routeParameters/routeParametersReducer";
import simulationReducer from "./simulation/simulationReducer";
import simulationParametersReducer from "./simulationParameters/simulationParametersReducer";

const simulationEingelenkregelung = combineReducers({
    routeParameters: routeParametersReducer,
    simulationParameters: simulationParametersReducer,
    simulation: simulationReducer
});

export default function (state, action) {
    return simulationEingelenkregelung(state, action);
}
