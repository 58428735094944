import React from 'react';

class Navbar extends React.Component {
    render() {
        return (
            <nav className="navbar navbar-dark bg-behance">
                <div className="container">
                    <span className="navbar-brand mb-0 h3">Simulation Zweigelenkregelung</span>
                    <span className="text-light">Dr. Wolfgang Weber</span>
                </div>
            </nav>
        )
    }
}

export default Navbar;
