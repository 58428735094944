import {ROUTE_PARAMETERS_SET_VALUE} from "../../config/actions";

let defaultState = {
    C1: '1',
    C2: '0.8',
    JA1: '0.00025',
    JA2: '0.00025',
    KMI1: '1',
    KMI2: '1',
    FM1: '0.00996',
    FM2: '0.00008',
    MR01: '0.09',
    MR02: '0.09',

    h1: '0',
    h2: '1',
    u1: '400',
    u2: '100',
    q1_min: '0.1',
    q1_max: '1.5',
    q2_min: '90',
    q2_max: '270',
    g: '9.81',
    m1: '20',
    m2: '10',
    l2: '0.5',
    s1x: '0',
    s1y: '0',
    s1z: '0',
    s2x: '-0.2',
    s2y: '0',
    s2z: '0',
    I_1_00: '0.2',
    I_1_01: '0',
    I_1_02: '0',
    I_1_10: '0',
    I_1_11: '0.1',
    I_1_12: '0',
    I_1_20: '0',
    I_1_21: '0',
    I_1_22: '0.2',
    I_2_00: '0.1',
    I_2_01: '0',
    I_2_02: '0',
    I_2_10: '0',
    I_2_11: '0.2',
    I_2_12: '0',
    I_2_20: '0',
    I_2_21: '0',
    I_2_22: '0.2'
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ROUTE_PARAMETERS_SET_VALUE: {
            return {
                ...state,
                [action.name]: action.value
            }
        }
        default:
            return state;
    }
}
