import {log, ceil, zeros, multiply, ones, subset, index, matrix} from 'mathjs';
import {store} from "../store";
import {setSimulationValue} from "../store/simulation/simulationAction";
import RK2 from "./RK2";

export default function SimulationStrecke() {
    let M_Sterns_fs = store.getState().simulation.M_Stern;
    let k1_fs = store.getState().simulation.k1;
    let k2_fs = store.getState().simulation.k2;
    let T_St = M_Sterns_fs / k1_fs;

    let fipo = 50;
    let dt_Strecke = T_St / fipo;

    let t_lauf_proz = 99.9;
    let t_lauf = -1 * log(1 - t_lauf_proz / 100) * T_St;
    // @TODO: woher kommt t_rech?!
    let t_rech = dt_Strecke;
    let lauf_ts = ceil(t_lauf / t_rech);
    store.dispatch(setSimulationValue('lauf', lauf_ts));

    let q_ts = zeros(1, lauf_ts);
    let US0_fs = parseFloat(store.getState().simulationParameters.US0);
    let US_ts = multiply(US0_fs, ones(1, lauf_ts));
    let q_st_fs = parseFloat(store.getState().simulationParameters.q_st);
    q_ts = subset(q_ts, index(0, 0), q_st_fs);
    let qp_ts = zeros(1, lauf_ts);
    let Zeit_ts = zeros(lauf_ts);
    let x_neu = matrix([[subset(q_ts, index(0, 0))], [subset(qp_ts, index(0, 0))]]);
    let t_akt = 0;

    let x;
    for (let i1 = 0; i1 < lauf_ts; i1++) {
        x = x_neu;
        x_neu = RK2(subset(US_ts, index(0, i1)), x, dt_Strecke, M_Sterns_fs, k1_fs, k2_fs, store.getState().simulation.G0, store.getState().routeParameters.h, parseFloat(store.getState().routeParameters.eps));
        t_akt = t_akt + dt_Strecke;
        q_ts = subset(q_ts, index(0, i1 + 1), subset(x_neu, index(0, 0)));
        qp_ts = subset(qp_ts, index(0, i1 + 1), subset(x_neu, index(1, 0)));
        Zeit_ts = subset(Zeit_ts, index(i1 + 1), t_akt);
    }

    store.dispatch(setSimulationValue('q', q_ts));
    store.dispatch(setSimulationValue('US', US_ts));
    store.dispatch(setSimulationValue('qp', qp_ts));
    store.dispatch(setSimulationValue('Zeit', Zeit_ts));
}
