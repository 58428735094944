import React, {Fragment} from 'react';
import {setRouteParameterValue} from "../store/routeParameters/routeParametersAction";
import {connect} from "react-redux";
import VariableHelp from "./VariableHelp";

class RouteParameters extends React.Component {
    render() {
        const { routeParameters, setRouteParameterValue } = this.props;
        return (
            <div className="card">
                <div className="card-header">
                    <b>Streckenparameter</b>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="form-group col-3">
                            <div>
                                <VariableHelp text="Wert der Gravitationskonstante, z.B. 9.81 oder 10, kein Einfluss der Gravitation: g=0"/>
                                <label>
                                    C1
                                </label>
                            </div>
                            <div className="input-group input-group-sm">
                                <input type="text" value={routeParameters.C1} onChange={(e) => setRouteParameterValue('C1', e.target.value)} className="form-control"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">Nm/A</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-3">
                            <div>
                                <VariableHelp text="Wert der Gravitationskonstante, z.B. 9.81 oder 10, kein Einfluss der Gravitation: g=0"/>
                                <label>
                                    C2
                                </label>
                            </div>
                            <div className="input-group input-group-sm">
                                <input type="text" value={routeParameters.C2} onChange={(e) => setRouteParameterValue('C2', e.target.value)} className="form-control"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">Nm/A</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-3">
                            <div>
                                <VariableHelp text="Wert der Gravitationskonstante, z.B. 9.81 oder 10, kein Einfluss der Gravitation: g=0"/>
                                <label>
                                    JA1
                                </label>
                            </div>
                            <div className="input-group input-group-sm">
                                <input type="text" value={routeParameters.JA1} onChange={(e) => setRouteParameterValue('JA1', e.target.value)} className="form-control"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">kgm<sup>2</sup></span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-3">
                            <div>
                                <VariableHelp text="Wert der Gravitationskonstante, z.B. 9.81 oder 10, kein Einfluss der Gravitation: g=0"/>
                                <label>
                                    JA2
                                </label>
                            </div>
                            <div className="input-group input-group-sm">
                                <input type="text" value={routeParameters.JA2} onChange={(e) => setRouteParameterValue('JA2', e.target.value)} className="form-control"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">kgm<sup>2</sup></span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-3">
                            <div>
                                <VariableHelp text="Wert der Gravitationskonstante, z.B. 9.81 oder 10, kein Einfluss der Gravitation: g=0"/>
                                <label>
                                    KMI1
                                </label>
                            </div>
                            <div className="input-group input-group-sm">
                                <input type="text" value={routeParameters.KMI1} onChange={(e) => setRouteParameterValue('KMI1', e.target.value)} className="form-control"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">V/A</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-3">
                            <div>
                                <VariableHelp text="Wert der Gravitationskonstante, z.B. 9.81 oder 10, kein Einfluss der Gravitation: g=0"/>
                                <label>
                                    KMI2
                                </label>
                            </div>
                            <div className="input-group input-group-sm">
                                <input type="text" value={routeParameters.KMI2} onChange={(e) => setRouteParameterValue('KMI2', e.target.value)} className="form-control"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">V/A</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-3">
                            <div>
                                <VariableHelp text="Wert der Gravitationskonstante, z.B. 9.81 oder 10, kein Einfluss der Gravitation: g=0"/>
                                <label>
                                    MR01
                                </label>
                            </div>
                            <div className="input-group input-group-sm">
                                <input type="text" value={routeParameters.MR01} onChange={(e) => setRouteParameterValue('MR01', e.target.value)} className="form-control"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">Nm</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-3">
                            <div>
                                <VariableHelp text="Wert der Gravitationskonstante, z.B. 9.81 oder 10, kein Einfluss der Gravitation: g=0"/>
                                <label>
                                    MR02
                                </label>
                            </div>
                            <div className="input-group input-group-sm">
                                <input type="text" value={routeParameters.MR02} onChange={(e) => setRouteParameterValue('MR02', e.target.value)} className="form-control"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">Nm</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-6">
                            <div>
                                <VariableHelp text="Wert der Gravitationskonstante, z.B. 9.81 oder 10, kein Einfluss der Gravitation: g=0"/>
                                <label>
                                    FM1
                                </label>
                            </div>
                            <div className="input-group input-group-sm">
                                <input type="text" value={routeParameters.FM1} onChange={(e) => setRouteParameterValue('FM1', e.target.value)} className="form-control"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">Nm sec</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-6">
                            <div>
                                <VariableHelp text="Wert der Gravitationskonstante, z.B. 9.81 oder 10, kein Einfluss der Gravitation: g=0"/>
                                <label>
                                    FM2
                                </label>
                            </div>
                            <div className="input-group input-group-sm">
                                <input type="text" value={routeParameters.FM2} onChange={(e) => setRouteParameterValue('FM2', e.target.value)} className="form-control"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">Nm sec</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    routeParameters: state.routeParameters
});

export default connect(mapStateToProps, {
    setRouteParameterValue
})(RouteParameters);
