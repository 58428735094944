import React, {Fragment} from 'react';
import {setSimulationParameterValue} from "../store/simulationParameters/simulationParametersAction";
import {connect} from "react-redux";
import VariableHelp from "./VariableHelp";

class SimulationParameters extends React.Component {

    render() {
        const {simulationParameters, setSimulationParameterValue} = this.props;
        return (
            <Fragment>
                <div className="card">
                    <div className="card-header">
                        <b>Lastmasse (Punktmasse im TCP)</b>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="form-group col-3">
                                <div>
                                    <VariableHelp
                                        text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                    <label>
                                        mL
                                    </label>
                                </div>
                                <div className="input-group input-group-sm">
                                    <input type="text" value={simulationParameters.mL}
                                           onChange={(e) => setSimulationParameterValue('mL', e.target.value)}
                                           className="form-control"/>
                                    <div className="input-group-append">
                                            <span
                                                className="input-group-text">kg</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-2">
                    <div className="card-header">
                        <b>Bewegungsvorgabe (Rampenbahn)</b>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="form-group col-6">
                                <div>
                                    <VariableHelp
                                        text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                    <label>
                                        Bahnart
                                    </label>
                                </div>
                                <select className="form-control form-control-sm"
                                        value={simulationParameters.Bahnart}
                                        onChange={(e) => setSimulationParameterValue('Bahnart', parseInt(e.target.value))}>
                                    <option value="0">PTP-Bahn</option>
                                    <option value="1">Linearbahn</option>
                                </select>
                            </div>
                            <div className="form-group col-6">
                                <div>
                                    <VariableHelp
                                        text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                    <label>
                                        Start-, Zielpunkt
                                    </label>
                                </div>
                                <select className="form-control form-control-sm"
                                        value={simulationParameters.jointcoord}
                                        onChange={(e) => setSimulationParameterValue('jointcoord', parseInt(e.target.value))}>
                                    <option value="0">Gelenkkoordinaten</option>
                                    <option value="1">kartesische Koordinaten</option>
                                </select>
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    {simulationParameters.Bahnart === 0 ? (
                                        <Fragment>
                                            <div className="form-group col-6">
                                                <div>
                                                    <VariableHelp
                                                        text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                                    <label>
                                                        vm1
                                                    </label>
                                                </div>
                                                <div className="input-group input-group-sm">
                                                    <input type="text" value={simulationParameters.vm1}
                                                           onChange={(e) => setSimulationParameterValue('vm1', e.target.value)}
                                                           className="form-control"/>
                                                    <div className="input-group-append">
                                                <span
                                                    className="input-group-text">m/sec</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-6">
                                                <div>
                                                    <VariableHelp
                                                        text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                                    <label>
                                                        bm1
                                                    </label>
                                                </div>
                                                <div className="input-group input-group-sm">
                                                    <input type="text" value={simulationParameters.bm1}
                                                           onChange={(e) => setSimulationParameterValue('bm1', e.target.value)}
                                                           className="form-control"/>
                                                    <div className="input-group-append">
                                            <span
                                                className="input-group-text">m/sec<sup>2</sup></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-6">
                                                <div>
                                                    <VariableHelp
                                                        text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                                    <label>
                                                        vm2
                                                    </label>
                                                </div>
                                                <div className="input-group input-group-sm">
                                                    <input type="text" value={simulationParameters.vm2}
                                                           onChange={(e) => setSimulationParameterValue('vm2', e.target.value)}
                                                           className="form-control"/>
                                                    <div className="input-group-append">
                                            <span
                                                className="input-group-text">1/sec</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-6">
                                                <div>
                                                    <VariableHelp
                                                        text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                                    <label>
                                                        bm2
                                                    </label>
                                                </div>
                                                <div className="input-group input-group-sm">
                                                    <input type="text" value={simulationParameters.bm2}
                                                           onChange={(e) => setSimulationParameterValue('bm2', e.target.value)}
                                                           className="form-control"/>
                                                    <div className="input-group-append">
                                            <span
                                                className="input-group-text">1/sec<sup>2</sup></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <div className="form-group col-6">
                                                <div>
                                                    <VariableHelp
                                                        text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                                    <label>
                                                        vp
                                                    </label>
                                                </div>
                                                <div className="input-group input-group-sm">
                                                    <input type="text" value={simulationParameters.vp}
                                                           onChange={(e) => setSimulationParameterValue('vp', e.target.value)}
                                                           className="form-control"/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">m/sec</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-6">
                                                <div>
                                                    <VariableHelp
                                                        text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                                    <label>
                                                        bp
                                                    </label>
                                                </div>
                                                <div className="input-group input-group-sm">
                                                    <input type="text" value={simulationParameters.bp}
                                                           onChange={(e) => setSimulationParameterValue('bp', e.target.value)}
                                                           className="form-control"/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">m/sec<sup>2</sup></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    {simulationParameters.jointcoord === 0 ? (
                                        <Fragment>
                                            <div className="form-group col-6">
                                                <div>
                                                    <VariableHelp
                                                        text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                                    <label>
                                                        q1_St
                                                    </label>
                                                </div>
                                                <div className="input-group input-group-sm">
                                                    <input type="text" value={simulationParameters.q1_St}
                                                           onChange={(e) => setSimulationParameterValue('q1_St', e.target.value)}
                                                           className="form-control"/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">m</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-6">
                                                <div>
                                                    <VariableHelp
                                                        text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                                    <label>
                                                        q1_Z
                                                    </label>
                                                </div>
                                                <div className="input-group input-group-sm">
                                                    <input type="text" value={simulationParameters.q1_Z}
                                                           onChange={(e) => setSimulationParameterValue('q1_Z', e.target.value)}
                                                           className="form-control"/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">m</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-6">
                                                <div>
                                                    <VariableHelp
                                                        text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                                    <label>
                                                        q2_St
                                                    </label>
                                                </div>
                                                <div className="input-group input-group-sm">
                                                    <input type="text" value={simulationParameters.q2_St}
                                                           onChange={(e) => setSimulationParameterValue('q2_St', e.target.value)}
                                                           className="form-control"/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">Grad</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-6">
                                                <div>
                                                    <VariableHelp
                                                        text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                                    <label>
                                                        q2_Z
                                                    </label>
                                                </div>
                                                <div className="input-group input-group-sm">
                                                    <input type="text" value={simulationParameters.q2_Z}
                                                           onChange={(e) => setSimulationParameterValue('q2_Z', e.target.value)}
                                                           className="form-control"/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">Grad</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <div className="form-group col-6">
                                                <div>
                                                    <VariableHelp
                                                        text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                                    <label>
                                                        x_St
                                                    </label>
                                                </div>
                                                <div className="input-group input-group-sm">
                                                    <input type="text" value={simulationParameters.x_St}
                                                           onChange={(e) => setSimulationParameterValue('x_St', e.target.value)}
                                                           className="form-control"/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">m</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-6">
                                                <div>
                                                    <VariableHelp
                                                        text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                                    <label>
                                                        z_St
                                                    </label>
                                                </div>
                                                <div className="input-group input-group-sm">
                                                    <input type="text" value={simulationParameters.z_St}
                                                           onChange={(e) => setSimulationParameterValue('z_St', e.target.value)}
                                                           className="form-control"/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">m</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-6">
                                                <div>
                                                    <VariableHelp
                                                        text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                                    <label>
                                                        x_Z
                                                    </label>
                                                </div>
                                                <div className="input-group input-group-sm">
                                                    <input type="text" value={simulationParameters.x_Z}
                                                           onChange={(e) => setSimulationParameterValue('x_Z', e.target.value)}
                                                           className="form-control"/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">m</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-6">
                                                <div>
                                                    <VariableHelp
                                                        text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                                    <label>
                                                        z_Z
                                                    </label>
                                                </div>
                                                <div className="input-group input-group-sm">
                                                    <input type="text" value={simulationParameters.z_Z}
                                                           onChange={(e) => setSimulationParameterValue('z_Z', e.target.value)}
                                                           className="form-control"/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">m</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-3">
                                <div>
                                    <VariableHelp
                                        text="Nachlaufzeit (Prozent Bahndauer)"/>
                                    <label>
                                        p
                                    </label>
                                </div>
                                <div className="input-group input-group-sm">
                                    <input type="text" value={simulationParameters.p}
                                           onChange={(e) => setSimulationParameterValue('p', e.target.value)}
                                           className="form-control"/>
                                    <div className="input-group-append">
                                        <span className="input-group-text">%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-2">
                    <div className="card-header">
                        <b>Struktur und Parameter der Regelung</b>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="form-group col-6">
                                <div>
                                    <VariableHelp
                                        text="Bei welcher Schublänge bzw. Winkel soll Sprung aufgeschaltet werden?"/>
                                    <label>
                                        Reglerstruktur
                                    </label>
                                </div>
                                <select className="form-control form-control-sm"
                                        value={simulationParameters.Regelungsstruktur}
                                        onChange={(e) => setSimulationParameterValue('Regelungsstruktur', parseInt(e.target.value))}>
                                    <option value="1">P-PI dezentral</option>
                                    <option value="2">PT2 modellbasiert</option>
                                    <option value="3">P-PI modellbasiert</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            {[1, 3].includes(simulationParameters.Regelungsstruktur) ? (
                                <Fragment>
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp
                                                text="Nachlaufzeit (Prozent Bahndauer)"/>
                                            <label>
                                                KP1
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.KP1}
                                                   onChange={(e) => setSimulationParameterValue('KP1', e.target.value)}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp
                                                text="Nachlaufzeit (Prozent Bahndauer)"/>
                                            <label>
                                                TN1
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.TN1}
                                                   onChange={(e) => setSimulationParameterValue('TN1', e.target.value)}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp
                                                text="Nachlaufzeit (Prozent Bahndauer)"/>
                                            <label>
                                                KP2
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.KP2}
                                                   onChange={(e) => setSimulationParameterValue('KP2', e.target.value)}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp
                                                text="Nachlaufzeit (Prozent Bahndauer)"/>
                                            <label>
                                                TN2
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.TN2}
                                                   onChange={(e) => setSimulationParameterValue('TN2', e.target.value)}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp
                                                text="Nachlaufzeit (Prozent Bahndauer)"/>
                                            <label>
                                                KL1
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.KL1}
                                                   onChange={(e) => setSimulationParameterValue('KL1', e.target.value)}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp
                                                text="Nachlaufzeit (Prozent Bahndauer)"/>
                                            <label>
                                                KVor1
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.KVor1}
                                                   onChange={(e) => setSimulationParameterValue('KVor1', e.target.value)}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp
                                                text="Nachlaufzeit (Prozent Bahndauer)"/>
                                            <label>
                                                KL2
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.KL2}
                                                   onChange={(e) => setSimulationParameterValue('KL2', e.target.value)}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp
                                                text="Nachlaufzeit (Prozent Bahndauer)"/>
                                            <label>
                                                KVor2
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.KVor2}
                                                   onChange={(e) => setSimulationParameterValue('KVor2', e.target.value)}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp
                                                text="Nachlaufzeit (Prozent Bahndauer)"/>
                                            <label>
                                                T1
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.T1}
                                                   onChange={(e) => setSimulationParameterValue('T1', e.target.value)}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp
                                                text="Nachlaufzeit (Prozent Bahndauer)"/>
                                            <label>
                                                d1
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.d1}
                                                   onChange={(e) => setSimulationParameterValue('d1', e.target.value)}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp
                                                text="Nachlaufzeit (Prozent Bahndauer)"/>
                                            <label>
                                                T2
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.T2}
                                                   onChange={(e) => setSimulationParameterValue('T2', e.target.value)}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <div>
                                            <VariableHelp
                                                text="Nachlaufzeit (Prozent Bahndauer)"/>
                                            <label>
                                                d2
                                            </label>
                                        </div>
                                        <div className="input-group input-group-sm">
                                            <input type="text" value={simulationParameters.d2}
                                                   onChange={(e) => setSimulationParameterValue('d2', e.target.value)}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    routeParameters: state.routeParameters,
    simulationParameters: state.simulationParameters
});

export default connect(mapStateToProps, {
    setSimulationParameterValue
})(SimulationParameters);
