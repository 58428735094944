import {sin, pow} from 'mathjs';
import {store} from "../store";
import {setSimulationValue} from "../store/simulation/simulationAction";

export default function Streckenmodell() {
    let KMI_fs = parseFloat(store.getState().routeParameters.KMI);
    let C_fs = parseFloat(store.getState().routeParameters.C);
    let KM = C_fs / KMI_fs;

    let h_fs = store.getState().routeParameters.h;
    let m_fs = parseFloat(store.getState().routeParameters.m);
    let g_fs = parseFloat(store.getState().routeParameters.g);
    let u_fs = parseFloat(store.getState().routeParameters.u);
    let M, G0_ts;
    if (h_fs === 1) {
        M = parseFloat(store.getState().routeParameters.JL);
        G0_ts = (m_fs * g_fs * parseFloat(store.getState().routeParameters.ls)) / (KM * u_fs);
    } else {
        M = m_fs;
        G0_ts = (m_fs * g_fs * sin(parseFloat(store.getState().routeParameters.beta_neig))) / (KM * u_fs);
    }
    store.dispatch(setSimulationValue('G0', G0_ts));

    let FM_fs = parseFloat(store.getState().routeParameters.FM);
    let FD_fs = parseFloat(store.getState().routeParameters.FD);
    let MR0_fs = parseFloat(store.getState().routeParameters.MR0);
    let MRG0_fs = parseFloat(store.getState().routeParameters.MRG0);
    let JA_fs = parseFloat(store.getState().routeParameters.JA);
    store.dispatch(setSimulationValue('k1', ((FM_fs * pow(u_fs, 2) + FD_fs) / (KM * u_fs))));
    store.dispatch(setSimulationValue('k2', ((MR0_fs * u_fs + MRG0_fs) / (KM * u_fs))));
    store.dispatch(setSimulationValue('M_Stern', ((1 / KM) * (M / u_fs + JA_fs * u_fs))));
}
