import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/@coreui/coreui/dist/css/coreui.css';
import '../node_modules/@coreui/icons/css/all.css';
import Navbar from "./elements/Navbar";
import RouteParameters from "./elements/RouteParameters";
import SimulationParameters from "./elements/SimulationParameters";
import Simulation from "./elements/Simulation";

function App() {
    return (
        <div className="App">
            <Navbar/>
            <div className="container">
                <div className="row mt-4">
                    <div className="col-12 col-lg-6">
                        <RouteParameters/>
                    </div>
                    <div className="col-12 col-lg-6">
                        <SimulationParameters/>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <Simulation/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
