import {size, max, round, zeros, subset, index, pow, dotPow, dotDivide, dotMultiply, squeeze, matrix} from 'mathjs';

export default function rampe(se, tb, tv, te, vm, am, dt) {
    let rows = subset(size(matrix([se])), index(0));

    let temax = max(te);
    let tast = round(temax / dt);

    let st = zeros(rows);
    let s = zeros(rows, tast);

    let vt = zeros(rows);
    let at = zeros(rows);
    let vel = zeros(rows, tast);
    let acc = zeros(rows, tast);

    let t;

    for (let j = 0; j < tast; j++) {
        t = j * dt;

        if (t >= 0 && t <= tb) {
            st = subset(st, index(0), 0.5 * am * pow(t, 2));
            vt = subset(vt, index(0), am * t);
            at = subset(at, index(0), am);
        } else if (t > tb && t <= tv) {
            st = subset(st, index(0), vm * t - dotDivide((dotPow(vm, 2)), (am * 2)));
            vt = subset(vt, index(0), vm);
            at = subset(at, index(0), 0);
        } else if (t > tv && t <= te) {
            st = subset(st, index(0), se - (dotMultiply((0.5 * am), dotPow((te - t), 2))));
            vt = subset(vt, index(0), dotMultiply(am, (te - t)));
            at = subset(at, index(0), am * -1);
        } else if (t > te) {
            st = subset(st, index(0), se);
            vt = subset(vt, index(0), 0);
            at = subset(at, index(0), 0);
        }

        s = subset(s, index([...Array(rows).keys()], j), squeeze(st));
        vel = subset(vel, index([...Array(rows).keys()], j), squeeze(vt));
        acc = subset(acc, index([...Array(rows).keys()], j), squeeze(at));
    }

    return {
        s,
        vel,
        acc
    }
}
