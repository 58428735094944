import {SIMULATION_PARAMETERS_SET_VALUE} from "../../config/actions";

let defaultState = {
    mL: '5',
    Bahnart: 0,
    jointcoord: 0,
    vm1: '0.8',
    bm1: '2.4',
    vm2: '1.2',
    bm2: '4',
    vp: '0.8',
    bp: '2.4',
    q1_St: '0.2',
    q1_Z: '1.2',
    q2_St: '120',
    q2_Z: '225',
    x_St: '0.3',
    z_St: '0.5',
    x_Z: '1.5',
    z_Z: '0.1',
    p: '20',
    Regelungsstruktur: 1,
    KP1: '4',
    TN1: '0.08',
    KP2: '5',
    TN2: '0.075',
    KL1: '2.5',
    KVor1: '0.9',
    KL2: '4',
    KVor2: '0.95',
    T1: '0.1',
    d1: '0.98',
    T2: '0.06',
    d2: '1'
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case SIMULATION_PARAMETERS_SET_VALUE: {
            return {
                ...state,
                [action.name]: action.value
            }
        }
        default:
            return state;
    }
}
