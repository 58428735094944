import React from 'react';
import {UncontrolledPopover, PopoverBody} from 'reactstrap';

class VariableHelp extends React.Component {
    render() {
        let elementId = 'variableHelp-' + Math.random().toString(36).substring(7);
        return (
            <div className="float-right">
                <i className="c-icon cil-info" id={elementId}/>
                <UncontrolledPopover target={elementId} trigger="click hover">
                    <PopoverBody>
                        {this.props.text}
                    </PopoverBody>
                </UncontrolledPopover>
            </div>
        )
    }
}
export default VariableHelp;
