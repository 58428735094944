import {sqrt, ceil, zeros, subset, index, matrix, squeeze} from 'mathjs';
import {store} from "../store";
import pi_alg from "./pi_alg";
import redus_alg from "./regus_alg";
import RK2 from "./RK2";
import {setSimulationValue} from "../store/simulation/simulationAction";

export default function SimulationRegelung() {
    let PI_GeschwRegler_fs = store.getState().simulationParameters.PI_GeschwRegler;
    let Lageregelung_fs = store.getState().simulationParameters.Lageregelung;
    let M_Stern_fs = store.getState().simulation.M_Stern;
    let k1_fs = store.getState().simulation.k1;
    let TN_fs = parseFloat(store.getState().simulationParameters.TN);
    let KP_fs = parseFloat(store.getState().simulationParameters.KP);
    let KI_fs = parseFloat(store.getState().simulationParameters.KI);
    let TR, dt_reg, k_zeit, Reg_par_1, Reg_par_2, par_int;

    if (PI_GeschwRegler_fs === 1) {
        let T_St = M_Stern_fs / k1_fs;
        let K_St = 1 / k1_fs;
        TR = sqrt((TN_fs * T_St) / (KP_fs * K_St));
    } else {
        TR = sqrt(M_Stern_fs / KI_fs);
    }

    let dt_reg_test = Number(TR / 50).toPrecision(2);
    let dt_darst_fs = store.getState().simulation.dt_darst;

    if (dt_reg_test > dt_darst_fs) {
        k_zeit = 1;
        dt_reg = dt_darst_fs;
    } else {
        k_zeit = ceil(dt_darst_fs / dt_reg_test);
        dt_reg = dt_darst_fs / k_zeit;
    }

    let lauf_fs = store.getState().simulation.lauf;
    let q_st_fs = parseFloat(store.getState().simulationParameters.q_st);

    let q = zeros(1, lauf_fs);
    q = subset(q, index(0, 0), q_st_fs);
    let qp = zeros(1, lauf_fs);
    let US = zeros(1, lauf_fs);
    let qp_akt = 0;
    let q_akt = q_st_fs;

    let x_neu = matrix([[subset(q, index(0, 0))], [subset(qp, index(0, 0))]]);

    let e_sum_alt = 0;
    let e_akt = 0;

    if (PI_GeschwRegler_fs === 1) {
        Reg_par_1 = KP_fs;
        Reg_par_2 = KP_fs * dt_reg / TN_fs;
    } else {
        par_int = KI_fs * dt_reg;
    }

    let eL_akt, vs, Stellwert, x;
    let qs_fs = store.getState().simulation.qs;
    let qps_fs = store.getState().simulation.qps;
    let k2_fs = store.getState().simulation.k2;
    let KL_fs = parseFloat(store.getState().simulationParameters.KL);
    let KVor_fs = parseFloat(store.getState().simulationParameters.KVor);
    let alpha_fs = parseFloat(store.getState().simulationParameters.alpha);
    let beta_fs = parseFloat(store.getState().simulationParameters.beta);
    let G0_fs = parseFloat(store.getState().simulation.G0);
    let h_fs = store.getState().routeParameters.h;
    let eps_fs = parseFloat(store.getState().routeParameters.eps);

    for (let i1 = 0; i1 < lauf_fs; i1++) {
        for (let l = 0; l < k_zeit; l++) {

            if (Lageregelung_fs === 1) {
                eL_akt = subset(qs_fs, index(i1)) - q_akt;
                vs = KL_fs * eL_akt + KVor_fs * subset(qps_fs, index(0, i1));
            } else {
                vs = subset(qps_fs, index(0, i1));
            }
            e_akt = vs - qp_akt;

            if (PI_GeschwRegler_fs === 1) {
                ({Stellwert, e_sum_alt} = pi_alg(Reg_par_1, Reg_par_2, e_akt, e_sum_alt))
            } else {
                ({Stellwert, e_sum_alt} = redus_alg(par_int, alpha_fs, beta_fs, e_akt, qp_akt, vs, e_sum_alt))
            }

            x = x_neu;
            x_neu = RK2(Stellwert, x, dt_reg, M_Stern_fs, k1_fs, k2_fs, G0_fs, h_fs, eps_fs);

            q_akt = subset(x_neu, index(0, 0));
            qp_akt = subset(x_neu, index(1, 0));
        }

        q = subset(q, index(0, i1), subset(x_neu, index(0, 0)));
        qp = subset(qp, index(0, i1), subset(x_neu, index(1, 0)));

        US = subset(US, index(0, i1), Stellwert);
    }

    store.dispatch(setSimulationValue('qsp_qp', matrix([squeeze(qps_fs).toArray(), squeeze(qp).toArray()])));
    store.dispatch(setSimulationValue('qs_q', matrix([squeeze(qs_fs).toArray(), squeeze(q).toArray()])));
    store.dispatch(setSimulationValue('US', US));
}
