import {abs, sqrt, sign, round, ceil, zeros, subset, index, multiply, ones, add, squeeze, matrix} from 'mathjs';
import {store} from "../store";
import rampe from "./rampe";
import {setSimulationValue} from "../store/simulation/simulationAction";

export default function Bewegungsvorgabe() {
    let Sprung_v_fs = store.getState().simulationParameters.Sprung_v;
    let t_lauf, vs0, se, tb, tv, te, vm_fs, bm_fs, Vorz, q_st_fs, q_z_fs;

    q_st_fs = parseFloat(store.getState().simulationParameters.q_st);
    if (Sprung_v_fs === 1) {
        t_lauf = parseFloat(store.getState().simulationParameters.t_lauf);
        vs0 = parseFloat(store.getState().simulationParameters.vs0);
    } else {
        q_z_fs = parseFloat(store.getState().simulationParameters.q_z);
        se = abs(q_z_fs - q_st_fs);

        vm_fs = parseFloat(store.getState().simulationParameters.vm);
        bm_fs = parseFloat(store.getState().simulationParameters.bm);

        if (vm_fs > sqrt(se * bm_fs)) {
            vm_fs = sqrt(se * bm_fs);
        }

        Vorz = sign(q_z_fs - q_st_fs);
        tb = vm_fs / bm_fs;
        tv = se / vm_fs;
        te = tv + tb;
        t_lauf = te + 0.5 * te;
        vs0 = 0;
    }

    let fipo_darst = 400;
    let dt_darst = parseFloat(Number(t_lauf / fipo_darst).toPrecision(2));
    let lauf = ceil(t_lauf / dt_darst);
    let Zeit_ts = zeros(lauf);
    for (let i1 = 0; i1 < lauf; i1++) {
        Zeit_ts = subset(Zeit_ts, index(i1), i1 * dt_darst);
    }

    let qs_ts, qps_ts;

    if (Sprung_v_fs === 1) {
        qs_ts = matrix([lauf]);
        qps_ts = multiply(vs0, ones(1, lauf));
        for (let i1 = 0; i1 < lauf; i1++) {
            qs_ts = subset(qs_ts, index(i1), q_st_fs + dt_darst * i1);
        }
    } else {
        let {s, vel, acc} = rampe(se, tb, tv, te, vm_fs, bm_fs, dt_darst);
        for (let i1 = (round(te / dt_darst) - 1); i1 < lauf; i1++) {
            vel = subset(vel, index(0, i1), 0);
            acc = subset(acc, index(0, i1), 0);
            s = subset(s, index(0, i1), se);
        }

        qs_ts = add(q_st_fs, multiply(Vorz, s));
        qps_ts = multiply(Vorz, vel);
        let qpps_ts = multiply(Vorz, acc);
        store.dispatch(setSimulationValue('qpps', qpps_ts));
    }
    store.dispatch(setSimulationValue('qs', squeeze(qs_ts)));
    store.dispatch(setSimulationValue('qps', qps_ts));
    store.dispatch(setSimulationValue('dt_darst', dt_darst));
    store.dispatch(setSimulationValue('lauf', lauf));
    store.dispatch(setSimulationValue('Zeit', Zeit_ts));

}
