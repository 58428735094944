export default function pi_alg(Reg_par_1, Reg_par_2, e_akt, e_sum_alt) {
    let e_sum_neu = e_sum_alt + e_akt;
    let Stellwert = Reg_par_1 * e_akt + Reg_par_2 * e_sum_alt;
    e_sum_alt = e_sum_neu;

    return {
        Stellwert,
        e_sum_alt
    }
}
